import styled from 'styled-components';
import Button from 'components/Button';

export const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 20px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #f1f4f9;
`;

export const Footer = styled.header`
  display: flex;
  padding: 20px 20px 20px;
  border-bottom: 1px solid #f1f4f9;
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: 600;
`;

export const Price = styled.p`
  font-size: 20px;
  font-weight: 600;
`;

export const Content = styled.div`
  padding: 20px 0 0;
`;

export const ContentTitle = styled.h2`
  font-size: 14px;
  font-weight: 600;
  color: #1c0c1e;
  margin-left: 20px;
`;

export const FullButton = styled(Button).attrs(() => ({
  color: 'white',
}))`
  width: 100%;
  text-align: center;
`;

export const Empty = styled.div`
  padding: 40px 0;
  text-align: center;

  svg {
    font-size: 50px;
    color: #d3dceb;
    margin: 0 auto 15px;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 5px;
    color: #5f6470;
  }

  p {
    font-size: 14px;
    color: #5f6470;
  }
`;
