import { ModalContent, ModalFooter, ModalTitle } from 'components/Modal';
import { Step } from '../../styles';
import Button from 'components/Button';
import { useForm } from '../../contexts/form';
import List from './components/List';
import * as S from './styles';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { useEffect, useRef } from 'react';

const PLAN_RESOURCES = {
  yearly: [
    'Todos recursos disponíveis',
    'Suporte via WhatsApp',
    'Acompanhamento por time de onboard',
    '2 treinamentos de 1 hora para sua equipe',
    'Até 2 horas para ajuste do design do site',
    '1 ano grátis de Imovelweb',
  ],
  monthly_with_deployment: [
    'Todos recursos disponíveis',
    'Suporte via WhatsApp',
    'Acompanhamento por time de onboard',
    '1 treinamento de 1 hora para sua equipe',
    'Até 1 hora para ajuste do design do site',
  ],
  monthly: ['Todos recursos disponíveis', 'Suporte via WhatsApp'],
};

export const PlanContract = () => {
  const { setPage } = useForm();
  const { values, plansPreview, fetchPlansPreview } = useModalPayment();

  // Referência para evitar re-fetch com os mesmos valores
  const prevValuesRef = useRef(null);

  useEffect(() => {
    const currentValues = {
      users_number: values.additional_users,
      mailboxes_number: values.mailboxes_number,
      is_orulo: values.is_orulo,
      is_dwv: values.is_dwv,
    };

    // Apenas chama `fetchPlansPreview` se os valores mudaram
    if (
      JSON.stringify(prevValuesRef.current) !== JSON.stringify(currentValues)
    ) {
      prevValuesRef.current = currentValues; // Atualiza a referência
      fetchPlansPreview(currentValues);
    }
  }, [values, fetchPlansPreview]);

  if (!plansPreview) return null;

  return (
    <>
      <ModalTitle
        text="Hora de pensar no longo prazo!"
        actions={
          <Step>
            Etapa
            <br />
            2/3
          </Step>
        }
      >
        Prazo de Contratação
      </ModalTitle>

      <ModalContent>
        <S.Wrapper>
          <List />
          <S.Package>
            <h2>O que é o pacote de implantação</h2>
            <p>
              O serviço de implantação é opcional, você pode seguir sua
              configuração com apoio de nosso suporte técnico sem custo, ou
              optar em contratar nosso serviço de implementação que fará toda a
              configuração da sua conta até a criação junto com você.
            </p>
            <br />
            <p>
              Também estão inclusos ajustes na personalização do seu site e
              treinamento completo para você e sua equipe via reunião online.
            </p>
            <h3>Quais serviços estão incluídos no pacote?</h3>
            <ul>
              {values.type_plan &&
                PLAN_RESOURCES[values.type_plan].map((plan, key) => (
                  <li key={plan + key}>
                    <span>{plan}</span>
                  </li>
                ))}
            </ul>
          </S.Package>
        </S.Wrapper>

        <ModalFooter>
          <Button
            color="white"
            colorText="primary"
            onClick={() => setPage('details')}
          >
            Voltar
          </Button>
          <div className="h-flex__cell--grow" />
          <Button
            type="submit"
            color="success"
            onClick={() => setPage('payment')}
          >
            Ir para pagamento
          </Button>
        </ModalFooter>
      </ModalContent>
    </>
  );
};
