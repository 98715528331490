import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { contractWithPix } from 'services/financial/transactions';

export const usePix = ({
  type_plan = null,
  users,
  months,
  mailboxes_number,
  redirect_mails_number,
  credit_id,
  is_dwv,
  is_orulo,
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [code, setCode] = useState(null);
  const [generatedCreditId, setGeneratedCreditId] = useState(null);

  /**
   * Contrata com pix
   * @return {Promise<void>}
   */
  const contract = async () => {
    // if (process.env.NODE_ENV === 'development') {
    //   setCode(pixMock.data.pix_qr_code);
    //   setGeneratedCreditId(pixMock.data.credit_id);
    //   return;
    // }

    const {
      data: { pix_qr_code, credit_id: creditId },
    } = await contractWithPix({
      additional_users: users,
      type_plan,
      months,
      mailboxes_number,
      redirect_mails_number,
      credit_id,
      is_dwv,
      is_orulo,
    });

    setGeneratedCreditId(creditId);
    setCode(pix_qr_code);
  };

  useEffect(() => {
    if (code) {
      QRCode.toDataURL(code, { width: 140, margin: 0 }, function (_, url) {
        setImageUrl(url);
      });
    }
  }, [code]);

  return { code, imageUrl, contract, creditId: generatedCreditId };
};
