import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import { openModal } from 'modules/modal';
import { useModalPosting } from './hooks/useModalPosting';
import FormStorePosting from './components/FormStorePosting';
import { useDispatch } from 'react-redux';
import { useStorePostings } from '../../hooks/usePostings';
import { useEffect, useMemo } from 'react';
import moment, { format } from 'lib/moment';
import { useShowMoviment } from '../../hooks/useMoviments';

export const openModalPosting =
  ({ movimentId, postingId, type = 1, posting, onSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalPosting',
        size: 470,
        position: 'center',
        props: {
          movimentId,
          postingId,
          type,
          posting,
          onSuccess,
        },
      })
    );
  };

export function ModalPosting({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  movimentId,
  postingId,
  type: _type = 1,
  posting,
  onSuccess,
}) {
  const dispatch = useDispatch();
  const { moviment, movimentIsFetching, fetchMoviment } = useShowMoviment();

  const type = useMemo(() => {
    if (moviment?.posting?.id) {
      return moviment?.posting?.is_debit ? 2 : 1;
    }

    return _type;
  }, [_type, moviment]);

  const { storePostings } = useStorePostings({
    isMoviment: !!movimentId && !!postingId,
    type,
  });

  const { configs } = useModalPosting({ type, posting, moviment });

  useEffect(() => {
    if (movimentId && postingId) {
      fetchMoviment({ postingId, movimentId });
    }
  }, [movimentId, postingId]);

  const reopenModal = (values) => {
    dispatch(
      openModalPosting({
        type,
        posting: values,
        onSuccess,
      })
    );
  };

  const handleOnCreatePerson = (values) => {
    reopenModal(values);
  };

  const initialValues = useMemo(() => {
    if (moviment?.id) {
      return moviment;
    }

    return {
      [configs.date_field_name]: moment().format(format.date),
      repetition_type: 'dont-repeat',
      recurrency_type_id: '865425c2-6729-40a3-b76d-5283e8252f8c',
      ...posting,
    };
  }, [posting, moviment]);

  const onSubmitSuccess = (res) => {
    if (onSuccess) onSuccess(res);
    handleClose();
  };

  if (movimentIsFetching) return null;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={configs.titleModal} handleClose={handleClose}>
        <FormStorePosting
          moviment={moviment}
          initialValues={initialValues}
          type={type}
          configs={configs}
          handleClose={handleClose}
          onCreatePerson={handleOnCreatePerson}
          reopenModal={reopenModal}
          onSubmit={(values) => {
            return storePostings({
              postingId,
              movimentId,
              ...values,
            });
          }}
          onSubmitSuccess={onSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}
