import { Header } from 'features/Financial/components/Header';
import { Financial } from 'features/Financial';
import { TableMoviments } from './components/TableMoviments';
import { useMovimentsList } from 'features/Financial/hooks/usePostings';
import { MetaInformations } from './components/MetaInformations';
import { FilterPostings, selector } from './components/FilterPostings';
import moment, { format } from 'lib/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { change } from 'redux-form';

export function ListPostings() {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    start_at: moment().startOf('month').format(format.date),
    end_at: moment().endOf('month').format(format.date),
  });

  const { fetchMoviments, isFetchingMoviments, moviments, movimentsMeta } =
    useMovimentsList();

  const date = useSelector((state) => selector(state, 'start_at', 'end_at'));

  const isDateRangeGreaterThanOneMonth = useMemo(() => {
    const start = moment(date?.start_at, format.date);
    const end = moment(date?.end_at, format.date);
    return end.diff(start, 'months', true) > 1;
  }, [date]);

  const handleDateChange = (value) => {
    const startOfMonth = moment(value).startOf('month').format(format.date);
    const endOfMonth = moment(value).endOf('month').format(format.date);

    dispatch(change('FilterPostings', 'start_at', startOfMonth));
    dispatch(change('FilterPostings', 'end_at', endOfMonth));
  };

  return (
    <>
      <Header.Header>
        <Header.Title>Lançamentos</Header.Title>
        <Header.Actions>
          <FilterPostings
            onSubmit={fetchMoviments}
            initialValues={initialValues}
            onAddPosting={fetchMoviments}
            onChange={fetchMoviments}
          />
        </Header.Actions>
      </Header.Header>
      <Financial.Content isFetching={isFetchingMoviments}>
        {!isDateRangeGreaterThanOneMonth && (
          <Financial.DateSelector
            initialValue={moment(date.start_at, format.date).format('MM-YYYY')}
            type="month"
            onChange={(value) => handleDateChange(value)}
            className="h-margin-bottom--15"
          />
        )}
        <TableMoviments data={moviments} />
        <MetaInformations meta={movimentsMeta} />
      </Financial.Content>
    </>
  );
}
