import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  MdAttachMoney,
  MdEmail,
  MdInput,
  MdPayment,
  MdPerson,
  MdSettings,
  MdShare,
  MdSwapHoriz,
} from 'react-icons/md';
// Components
import ItemDropdown, { Divider, Item } from '../../ItemDropdown';
import Icon from 'components/Icon';
// Containers
import IsTecimob from 'containers/IsTecimob';
import Can from 'containers/Can';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'modules/login';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { IoMdGift } from 'react-icons/io';
import { openModalRecommendation } from 'modules/modal';

function MenuConfigs() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const canShowFinancial = useMemo(() => {
    return currentUser?.real_estate?.resale?.name !== 'Apple';
  }, [currentUser]);

  const handleClickLogoff = useCallback(() => {
    dispatch(logoutUser());
  }, []);

  const handleOpenRecommendationModal = useCallback(() => {
    dispatch(openModalRecommendation());
  }, []);

  return (
    <ItemDropdown
      position="right"
      href="#"
      dropdownClassName="js-wizard-ajustes"
      className="Menu__item Menu__item--small"
      label={
        <>
          <MdSettings className="h-margin-left--10" style={{ fontSize: 20 }} />{' '}
          <Icon name="chevron-down" />
        </>
      }
    >
      {({ close }) => (
        <>
          <Item onClick={close}>
            <Link to="/config">
              <MdSettings /> Ajustes gerais
            </Link>
          </Item>
          <Divider />
          <Item>
            <Link to="/users">
              <MdPerson /> Corretores/Usuários
            </Link>
          </Item>
          <Can run="EDIT_PORTAL">
            <Item>
              <Link to="/portals">
                <MdShare /> Portais imobiliários
              </Link>
            </Item>
          </Can>
          <Can run="EDIT_INTEGRATION">
            <Item onClick={close}>
              <Link to="/integrations">
                <MdSwapHoriz /> Integrações
              </Link>
            </Item>
          </Can>
          <Item onClick={close}>
            <Link to="/indices">
              <MdAttachMoney /> Indíces financeiros
            </Link>
          </Item>
          <Can run="EDIT_EMAIL">
            <Item>
              <Link to="/emails">
                <MdEmail /> Contas de e-mail
              </Link>
            </Item>
          </Can>
          {canShowFinancial && (
            <IsTecimob>
              <Item onClick={close}>
                <Link to="/plan/history">
                  <MdPayment /> Pagamentos
                </Link>
              </Item>
            </IsTecimob>
          )}
          <Item onClick={close}>
            <a href="#recommendations" onClick={handleOpenRecommendationModal}>
              <IoMdGift /> Recomendações
            </a>
          </Item>
          <Divider />
          <Item onClick={close}>
            <a href="#leave" onClick={handleClickLogoff}>
              <MdInput /> Sair
            </a>
          </Item>
        </>
      )}
    </ItemDropdown>
  );
}

MenuConfigs.defaultProps = {
  handleClickLogoff: null,
};

MenuConfigs.propTypes = {
  handleClickLogoff: PropTypes.func,
};

export default MenuConfigs;
