import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
// Containers
import ContainerPosts from '../../containers/ContainerPosts';
import ContainerForm from '../../containers/ContainerForm';
// Modules
import { openModalPostOptions } from 'modules/modal';
import {
  fetchPosts,
  postsSelectors,
} from 'pages/Site/pages/ContentSite/pages/Posts/module';
import { MdEdit } from 'react-icons/md';
import image from './image.svg';
import NoContent from 'pages/PropertyStore/components/NoContent';
import IsTecimob from 'containers/IsTecimob';
import Loading from 'components/Loading';

function List() {
  const dispatch = useDispatch();

  const posts = useSelector(postsSelectors.getAll);
  const isFetching = useSelector(postsSelectors.isFetching);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  const handleClickCreatePost = useCallback(() => {
    dispatch(openModalPostOptions());
  }, [dispatch]);

  if (isFetching) {
    return <Loading isVisible isFullScreen />;
  }

  if (posts?.length <= 0) {
    return (
      <NoContent
        image={image}
        title="Postagens"
        text="As postagens possibilitam entregar conteúdo para seu cliente e também o ajudam muito em questão de SEO, fazendo seu site ser considerado com algo a mais para o Google e ajudando no seu posicionamento. Crie postagens sobre temas como serviços prestados, sua região de atuação e especialidades. "
      >
        <Button color="secondary" onClick={handleClickCreatePost}>
          <MdEdit /> Escrever postagem
        </Button>
        <IsTecimob>
          <div>
            <a
              href="https://tecimob.com.br/blog/leis-imobiliarias-que-voce-precisa-conhecer"
              target="_blank"
              className="h-link h-margin-top--10"
              style={{ display: 'block' }}
              rel="noreferrer"
            >
              Ver exemplo de postagem ➝
            </a>
          </div>
        </IsTecimob>
      </NoContent>
    );
  }

  return (
    <>
      <MainTitle title="Postagens" text="Crie postagens e publique em seu site">
        <Button onClick={handleClickCreatePost} color="secondary">
          Criar Postagem
        </Button>
      </MainTitle>
      <ContainerForm />
      <ContainerPosts posts={posts} />
    </>
  );
}

List.propTypes = {};
List.defaultProps = {};

export default List;
