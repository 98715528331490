// import * as S from './styles'

import { Info } from 'containers/ModalRoot/ModalPayment/pages/Plan/components/PlanItem/styles';
import { formatter } from 'hooks/usePlan';

export function AllInfos({ plan }) {
  // Verifica se é parcelamento unico
  // Ex.: Boleto/Cartão mensal
  if (plan.installmentsSlip === 1 && plan.installmentsCreditCard === 1) {
    return <Info>Renovado mês a mês</Info>;
  }

  // Verifica se a parcela de boleto é igual a de cartão
  // Se sim simplifica a visualização dos dados
  if (plan.installmentsSlip === plan.installmentsCreditCard) {
    return (
      <>
        <Info>
          Parcele em ate <strong>{plan.installmentsSlip}x</strong> de{' '}
          <strong>
            R$ {formatter.format(plan.netValue / plan.installmentsSlip)}
          </strong>
        </Info>
      </>
    );
  }

  // Padrão quando não se enquadrar em nenhuma das opções acima
  return (
    <>
      {plan.installmentsSlip === 1 ? (
        <Info>
          Pagamento único de <strong>R$ {plan.totalInstallmentsSlip}</strong> no
          boleto
        </Info>
      ) : (
        <Info>
          Parcele em ate <strong>{plan.installmentsSlip}x</strong> de{' '}
          <strong>R$ {plan.totalInstallmentsSlip}</strong> no boleto
        </Info>
      )}

      {plan.installmentsCreditCard === 1 ? (
        <Info>
          Pagamento único de{' '}
          <strong>R$ {plan.totalInstallmentsCreditCard}</strong> no cartão
        </Info>
      ) : (
        <Info>
          ou em até <strong>{plan.installmentsCreditCard}x</strong> de{' '}
          <strong>R$ {plan.totalInstallmentsCreditCard}</strong> no cartão
        </Info>
      )}
    </>
  );
}
