import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import { Form } from 'containers/ModalRoot/ModalPayment/pages/Payment/styles';
import { SectionHeader } from 'containers/ModalRoot/ModalPayment/styles';
import { useCreditCard } from 'hooks/useCreditCard';
import useReduxForm from 'hooks/useReduxForm';
import { useEffect, useState } from 'react';
import { Row } from 'react-flexbox-grid';
import FieldInstallments from '../../components/FieldInstallments';
import CardsSelect from './components/CardsSelect';
import CreateCard from './components/CreateCard';
import Loading from 'components/Loading';
import { useModalUser } from 'containers/ModalRoot/ModalContractExtra/pages/UserAndEmail/contexts/ModalUserContext';
import { Field } from 'redux-form';
import { Input } from 'components/Form';
import { normalizeCnpj, normalizeCpf, normalizeNumbers } from 'lib/formHelpers';

const required = (value) =>
  value || typeof value === 'number' ? undefined : 'Campo obrigatório';

const CreditCard = () => {
  const { change, dispatch, getValues, initialize } = useReduxForm();
  const [isSelectingCard, setIsSelectingCard] = useState(true);

  const { setPage } = useModalUser();

  const { data: creditCards, meta, isFetching } = useCreditCard();

  const primaryCreditCard = (creditCards ?? []).find((card) => card.is_primary);

  function handleChangeCpfCnpj(value) {
    const normalizedValue = normalizeNumbers(value);
    return normalizedValue.length > 11
      ? normalizeCnpj(normalizedValue)
      : normalizeCpf(normalizedValue);
  }

  useEffect(() => {
    const values = getValues();

    if (!primaryCreditCard) return;

    initialize({
      ...values,
      card: primaryCreditCard.id,
      cpf_cnpj: meta?.cpf_cnpj,
    });
  }, [primaryCreditCard, meta]);

  function renderForm() {
    if (isSelectingCard && creditCards.length > 0) {
      return (
        <>
          <CardsSelect
            creditCards={creditCards}
            onAddCard={() => {
              dispatch(change('card', null));
              setIsSelectingCard(false);
            }}
          />
          <Row className="h-margin-top--15">
            <Field
              xs={4}
              label="CPF ou CNPJ"
              name="cpf_cnpj"
              component={Input}
              validate={[required]}
              normalize={handleChangeCpfCnpj}
            />
            <FieldInstallments xs={12} />
          </Row>
        </>
      );
    }

    return <CreateCard />;
  }

  if (isFetching) return <Loading isVisible isBlock />;

  return (
    <>
      <SectionHeader>Dados do cartão</SectionHeader>

      <Form>{renderForm()}</Form>

      <ModalFooter>
        <Button
          color="white"
          colorText="primary"
          onClick={() => setPage('Preview')}
        >
          Voltar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Finalizar pagamento
        </Button>
      </ModalFooter>
    </>
  );
};

export default CreditCard;
