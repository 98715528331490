import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import Modal from 'react-modal';
// Common Components
import FormCard from './components/FormCard';
import { hideLoading, showLoading } from 'modules/loading';
// Services
import { contractWithCreditCard } from 'services/financial/transactions';
import * as creditCardsService from 'services/financial/creditCards';
import { useCreditCards } from 'pages/PlanConfig/hooks/useCreditCards';

const ModalPayment = ({
  modalType,
  modalConfig,
  isOpen,
  handleClose,
  credit_id,
  users,
  months,
  onSubmitSuccess,
  onSubmitFail,
}) => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const { isFetching: isFetchingCards, meta } = useCreditCards();

  const isContractMode = (!!users && !!months) || !!credit_id;

  const params = credit_id ? { credit_id } : { users, months };

  const messageSuccess = useCallback(() => {
    if (!isContractMode) {
      Alert.success('Cartão adicionado');
    }
  }, [isContractMode]);

  const initialValues = useMemo(() => {
    return {
      receiving_method: 2,
      number: '',
      holder_name: '',
      expiration_date: '',
      cvv: '',
      recurring_charge: null,
      cpf_cnpj: meta.cpf_cnpj,
    };
  }, [meta]);

  const onSubmit = async (values) => {
    setIsFetching(true);

    try {
      const res = await creditCardsService.create(values);

      if (isContractMode) {
        await contractWithCreditCard(params);
        dispatch(showLoading());
      }

      messageSuccess();

      return res;
    } catch {
      dispatch(hideLoading());
    } finally {
      setIsFetching(false);
    }
  };

  const handleSuccess = useCallback(
    (...rest) => {
      if (onSubmitSuccess) onSubmitSuccess(...rest);
      handleClose();
    },
    [onSubmitSuccess, handleClose]
  );

  const handleFail = useCallback(
    (...rest) => {
      if (onSubmitFail) onSubmitFail(...rest);
    },
    [onSubmitFail]
  );

  if (isFetchingCards) return null;

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <FormCard
        isContractMode={isContractMode}
        isLoading={isFetching}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={handleSuccess}
        onSubmitFail={handleFail}
        handleClose={handleClose}
      />
    </Modal>
  );
};

ModalPayment.propTypes = {
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  credit_id: PropTypes.string,
  users: PropTypes.number,
  months: PropTypes.number,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

ModalPayment.defaultProps = {
  credit_id: null,
  users: null,
  months: null,
  onSubmitSuccess: null,
  onSubmitFail: null,
};

export default ModalPayment;
