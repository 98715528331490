import React from 'react';
import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
import { editOrulo, getPropertyPortal } from 'services/properties';
import { useRequest } from 'hooks/useRequest';

function ModalPropertyEditOrulo({
  id,
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  onSubmitSuccess,
}) {
  const { data: property } = useRequest({
    request: () => getPropertyPortal({ id }),
  });

  const onSubmit = (values) => {
    return editOrulo(values);
  };

  return (
    <Modal
      {...modalConfig}
      isFetching={isFetching}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Editar imóvel" handleClose={handleClose}>
        <Form
          initialValues={{
            is_featured: property?.is_featured,
            stripe_text: property?.stripe_text,
            stripe_background: property?.stripe_background,
            reference: property?.reference,
            featured_lists_id: property?.featured_lists_id,
            property_id: property?.property_id,
          }}
          onSubmit={onSubmit}
          onSubmitSuccess={() => {
            handleClose();
            if (onSubmitSuccess) onSubmitSuccess();
          }}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalPropertyEditOrulo;
