import * as Panel from '../Panel';
import { MdOutlineReceipt } from 'react-icons/all';

export function EmptyState() {
  return (
    <Panel.Wrapper>
      <Panel.Header>
        <Panel.Title>Projeção de Receitas e Despesas Mensais</Panel.Title>
      </Panel.Header>
      <Panel.Content>
        <Panel.Empty>
          <MdOutlineReceipt />
          <h2>Ainda não há dados disponíveis para projeção</h2>
        </Panel.Empty>
      </Panel.Content>
    </Panel.Wrapper>
  );
}
