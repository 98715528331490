import { Field } from 'redux-form';
import PlanItem from '../PlanItem';
import { Wrapper } from './styles';
import useReduxForm from 'hooks/useReduxForm';
import * as S from 'containers/ModalRoot/ModalPayment/pages/PlanContract/components/PlanItem/styles';
import { formatter } from 'hooks/usePlan';
import { useEffect } from 'react';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';

const List = () => {
  const { dispatch, change } = useReduxForm();
  const { plansPreview } = useModalPayment();

  useEffect(() => {
    // Inicializa com implantação anual
    dispatch(change('type_plan', 'yearly'));
  }, []);

  return (
    <Wrapper>
      <Field name="type_plan" component={PlanItem} props={{ value: 'yearly' }}>
        {({ checked, prices, handleSelect }) => {
          const plan = plansPreview?.yearly;

          return (
            <S.Wrapper active={checked} onClick={handleSelect}>
              <S.Main>
                <S.Infos>
                  <S.Title>Pagamento Anual + Implantação Grátis</S.Title>
                  <S.Info>
                    Parcele em até <strong>{plan?.cc_max_installments}x</strong>{' '}
                    de{' '}
                    <strong>
                      R$ {formatter.format(plan.installment_price)}
                    </strong>
                  </S.Info>
                  <S.Info>
                    Total anual{' '}
                    <strong>R$ {formatter.format(plan.yearly_price)}</strong>
                  </S.Info>
                </S.Infos>

                <S.PricesLabels>
                  <S.Info>De</S.Info>
                  <S.Discount>Grátis</S.Discount>
                  <S.NewPrice>Por</S.NewPrice>
                </S.PricesLabels>
              </S.Main>
              <S.Prices>
                <S.OldPrice>
                  R$ {formatter.format(plan?.original_price)}
                </S.OldPrice>
                <S.Info>- R$ {formatter.format(plan.deployment_price)}</S.Info>
                <S.NewPrice>
                  R$ {formatter.format(plan?.final_price)}
                </S.NewPrice>
              </S.Prices>
            </S.Wrapper>
          );
        }}
      </Field>

      <Field
        name="type_plan"
        component={PlanItem}
        label="Pagamento Semestral"
        props={{ value: 'monthly_with_deployment' }}
      >
        {({ checked, prices, handleSelect }) => {
          const plan = plansPreview?.monthly_with_deployment;

          return (
            <S.Wrapper active={checked} onClick={handleSelect}>
              <S.Main>
                <S.Infos>
                  <S.Title>Pagamento Mensal + Implantação</S.Title>
                  <S.Info>
                    Primeiro pagamento{' '}
                    <strong>R$ {formatter.format(plan.final_price)}</strong>{' '}
                    depois{' '}
                    <strong>R$ {formatter.format(plan?.monthly_price)}</strong>{' '}
                    por mês
                  </S.Info>
                  <S.Info>
                    Total anual{' '}
                    <strong>R$ {formatter.format(plan?.yearly_price)}</strong>
                  </S.Info>
                </S.Infos>

                <S.PricesLabels>
                  <S.Info>1º mês</S.Info>
                  <S.Info style={{ lineHeight: '18px' }}>Implantação</S.Info>
                  <S.NewPrice>Total</S.NewPrice>
                </S.PricesLabels>
              </S.Main>
              <S.Prices>
                <S.Info>R$ {formatter.format(plan?.monthly_price)}</S.Info>
                <S.Info>R$ {formatter.format(plan.deployment_price)}</S.Info>
                <S.NewPrice>
                  R$ {formatter.format(plan?.final_price)}
                </S.NewPrice>
              </S.Prices>
            </S.Wrapper>
          );
        }}
      </Field>

      <Field
        name="type_plan"
        component={PlanItem}
        label="Pagamento Trimestral"
        props={{ value: 'monthly' }}
      >
        {({ checked, handleSelect }) => {
          const plan = plansPreview?.monthly;

          return (
            <S.Wrapper active={checked} onClick={handleSelect}>
              <S.Main>
                <S.Infos>
                  <S.Title>Pagamento Mensal</S.Title>
                  <S.Info>
                    Renovado mês a mês no valor de{' '}
                    <strong>R$ {formatter.format(plan?.final_price)}</strong>
                  </S.Info>
                  <S.Info>
                    Total anual{' '}
                    <strong>R$ {formatter.format(plan?.yearly_price)}</strong>
                  </S.Info>
                </S.Infos>
              </S.Main>
              <S.Prices>
                <S.NewPrice>
                  R$ {formatter.format(plan?.final_price)}
                </S.NewPrice>
              </S.Prices>
            </S.Wrapper>
          );
        }}
      </Field>
    </Wrapper>
  );
};

export default List;
