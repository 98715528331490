import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from '../modules';

const middlewares = [thunkMiddleware];

// if (process.env.NODE_ENV === 'development') {
//   const { createLogger } = require('redux-logger');
//
//   middlewares.push(
//     createLogger({
//       collapsed: true,
//     })
//   );
// }

// const composeEnhancers =
//   process.env.NODE_ENV === 'development'
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : compose;
const composeEnhancers = compose;

export default function configureStore(preloadedState) {
  return createStore(
    reducers,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}
