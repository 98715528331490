import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;

  gap: 10px;
`;
export const Left = styled.div`
  flex-shrink: 0;
  min-width: 150px;
  padding-right: 20px;
`;
export const UserName = styled.div`
  white-space: nowrap;
  font-size: 24px;
`;

export const Right = styled.div`
  flex-grow: 1;
`;
