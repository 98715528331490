import React from 'react';
// Components
import ItemNav from '../../ItemNav';
import { useCan } from 'hooks/useCan';
import { useCurrentUser } from 'hooks/useCurrentUser';

function MenuFinancial() {
  const canShowFinancial = useCan({
    run: 'VIEW_FINANCIAL',
  });

  const currentUser = useCurrentUser();

  if (!canShowFinancial || !currentUser?.real_estate?.can_use_financial)
    return null;

  return (
    <ItemNav
      active="/financial/"
      label="Financeiro"
      to="/financial/dashboard"
      exact
    />
  );
}

export default MenuFinancial;
