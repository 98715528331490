import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import { reduxForm } from 'redux-form';
import FieldSelectUser from 'containers/FieldSelectUser';
import Button from 'components/Button';
import { Row } from 'react-flexbox-grid';
import * as propertiesService from 'services/properties';

function ModalPropertiesChangeRealtor({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  properties,
  handleSubmit,
  isSubmitting,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <ModalTemplate
          isLoading={isSubmitting}
          title="Alterar corretor responsável"
        >
          <p className="h-margin-bottom--15">
            Selecione o novo corretor para os{' '}
            <strong>{properties.length} imóveis escolhidos</strong>. Esta ação
            transferirá a responsabilidade de gestão para o corretor designado.
          </p>
          <Row>
            <FieldSelectUser label="Selecione o corretor" xs={6} />
          </Row>
          <ModalFooter>
            <Button type="button" color="white" onClick={handleClose}>
              Cancelar
            </Button>
            <span className="h-flex__cell--grow" />
            <Button type="submit" color="success" disabled={isSubmitting}>
              Atualizar corretor
            </Button>
          </ModalFooter>
        </ModalTemplate>
      </form>
    </Modal>
  );
}

export default reduxForm({
  form: 'ModalPropertiesChangeRealtorForm',
  validate: (values) => {
    let errors = {};

    if (!values.user_id) {
      errors.user_id = ['Campo obrigatório'];
    }

    return errors;
  },
  onSubmit: (values, dispatch, props) => {
    const propertiesObj = props.properties.reduce((acc, propertyId) => {
      acc[propertyId] = {
        id: propertyId,
        user_id: values.user_id,
      };

      return acc;
    }, {});

    return propertiesService.propertiesMassUser(propertiesObj);
  },
  onSubmitSuccess: (res, dispatch, props) => {
    if (props.onSuccess) props.onSuccess(res, dispatch, props);
    props.handleClose();
  },
})(ModalPropertiesChangeRealtor);
