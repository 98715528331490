import Modal from 'react-modal';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import * as S from './styles';
import { MdCheck } from 'react-icons/md';
import Button from 'components/Button';
import Video from 'components/Video';

export { openModalReception, useModalReception } from './useModalReception';

export function ModalReception({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate handleClose={handleClose}>
        <S.Wrapper>
          <S.BoxLeft>
            <S.Title>Controle Financeiro Total em um Só Lugar</S.Title>
            <S.Text>
              Centralize e simplifique sua gestão financeira com um controle
              completo em um único lugar. Organize suas receitas, despesas,
              contas e cartões com facilidade, e tenha uma visão clara do seu
              fluxo de caixa para tomar decisões mais seguras e estratégicas.
            </S.Text>
            <S.List>
              <li>
                <S.Ball>
                  <MdCheck />
                </S.Ball>
                Realize lançamentos financeiros com agilidade
              </li>
              <li>
                <S.Ball>
                  <MdCheck />
                </S.Ball>
                Acompanhe seu fluxo de caixa em tempo real
              </li>
              <li>
                <S.Ball>
                  <MdCheck />
                </S.Ball>
                Cadastre e gerencie suas contas e cartões facilmente
              </li>
            </S.List>
          </S.BoxLeft>
          <S.BoxRight>
            <S.WrapVideo>
              <S.Title>Vídeo tutorial da área</S.Title>
              <Video url="https://www.youtube.com/watch?v=ixmbL8c9CLE&ab_channel=DolarioDigital" />
            </S.WrapVideo>
          </S.BoxRight>
        </S.Wrapper>
        <ModalFooter>
          <Button color="white" onClick={handleClose}>
            Fechar
          </Button>
          <span className="h-flex__cell--grow" />
          <Button color="success" onClick={handleClose}>
            Teste grátis por 7 dias!
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}
