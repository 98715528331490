import { ModalStoreCategory } from './ModalStoreCategory';
import { ModalStoreAccount } from './ModalStoreAccount';
import { ModalAjustAccount } from './ModalAjustAccount';
import { ModalAccountTransfer } from './ModalAccountTransfer';
import { ModalPosting } from './ModalPosting';
import { ModalMovimentDestroy } from './ModalMovimentDestroy';
import { ModalMovimentDetail } from './ModalMovimentDetail';
import { ModalMovimentPay } from './ModalMovimentPay';
import { ModalReception } from './ModalReception';

export const ModalsFinancial = {
  ModalStoreCategory,
  ModalStoreAccount,
  ModalAjustAccount,
  ModalAccountTransfer,
  ModalPosting,
  ModalMovimentDestroy,
  ModalMovimentDetail,
  ModalMovimentPay,
  ModalReception,
};
