import { createContext, useContext, useState } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';

export const FormContext = createContext({
  page: null,
  setPage: () => null,
});

export const FormProvider = ({ children }) => {
  const { prices } = useModalPayment();
  const currentUser = useCurrentUser();
  const [page, _setPage] = useState('details');

  const setPage = (page) => {
    // Se a imobiliária não for pagante
    // Se a imobiliária tiver o financeiro ativo
    // E a pagina que ele quiser abrir é a de plano
    // Redireciona pra sessão nova de contratação de plano
    if (
      !currentUser?.real_estate?.is_paying &&
      prices.is_financial_required &&
      page === 'plan'
    ) {
      _setPage('planContract');
      return;
    }

    _setPage(page);
  };

  return (
    <FormContext.Provider
      value={{
        page,
        setPage,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => useContext(FormContext);
