import * as S from './styles';

import { useMovimentsProjection } from 'features/Financial/hooks/useDashboard';
import * as Panel from '../Panel';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useEffect, useMemo } from 'react';
import { useDashboardContext } from '../../hooks/useDashboardContext';
import { EmptyState } from './EmptyState';

const currencyFormatter = (value) => `R$\u00A0${value.toFixed(2)}`;

export function GraphProjection() {
  const { refreshKey } = useDashboardContext();
  const { projection, fetchProjection, projectionIsFetching } =
    useMovimentsProjection();

  useEffect(() => {
    fetchProjection();
  }, [refreshKey]);

  // Transformar os valores de debits para positivo
  const data = useMemo(() => {
    return projection.map((item) => ({
      ...item,
      debits: Math.abs(item.debits),
    }));
  }, [projection]);

  if (data.length <= 0 && !projectionIsFetching) {
    return <EmptyState />;
  }

  return (
    <Panel.Wrapper>
      <Panel.Header>
        <Panel.Title>Projeção de Receitas e Despesas Mensais</Panel.Title>
      </Panel.Header>
      <Panel.Content>
        <S.GraphContainer>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 50, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="title" />
              <YAxis tickFormatter={currencyFormatter} />
              <Tooltip formatter={currencyFormatter} />
              <Legend />
              <Bar dataKey="credits" name="Receita" fill="#31D084" />
              <Bar dataKey="debits" name="Despesa" fill="#F84343" />
            </BarChart>
          </ResponsiveContainer>
        </S.GraphContainer>
      </Panel.Content>
    </Panel.Wrapper>
  );
}
