import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const Package = styled.div`
  width: 379px;
  padding: 20px;
  border-radius: 10px;
  background: #f9fbfc;

  h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 14px;
    margin: 10px 0;
    color: #646979;
  }

  p {
    color: #646979;
    font-size: 14px;
  }

  ul {
    font-size: 12px;
    color: #646979;
    gap: 3px;
    display: flex;
    flex-direction: column;
    list-style-position: inside;
    margin-left: 5px;

    span {
      display: inline-block;
      margin-left: -5px;
    }
  }
`;
