import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 55px;
`;

export const BoxLeft = styled.div`
  width: 500px;
  flex-shrink: 0;
  padding: 40px 25px;
`;

export const BoxRight = styled.div`
  width: 380px;
  flex: 1 0;
  padding: 40px 25px;
`;

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  line-height: 1.5em;
`;

export const List = styled.ul`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 15px;

  li {
    display: flex;
    gap: 10px;
    font-weight: 500;
  }
`;

export const Ball = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #9be6c2;
  color: #09121f;
  display: grid;
  place-items: center;
`;

export const WrapVideo = styled.div`
  h2 {
    font-size: 20px;
  }
`;
