// Components
import { Aside } from './components/Aside';
import { Layout } from './components/Layout';
import { Content } from './components/Content';
import * as Table from './components/Table';
import { Nodata } from './components/Nodata';
import { DateSelector } from './components/DateSelector';
import { AddPosting } from './components/AddPosting';

// Fields
import { FieldIcon } from './fields/FieldIcon';
import { FieldAccount } from './fields/FieldAccount';
import { FieldCategory } from './fields/FieldCategory';
import { FieldRecurrencyType } from './fields/FieldRecurrencyType';
import { FieldSituations } from './fields/FieldSituations';
import { FieldCategoryType } from './fields/FieldCategoryType';

// Screens
import { ListCategories } from './screens/ListCategories';
import { ListAccounts } from './screens/ListAccounts';
import { ListCards } from './screens/ListCards';
import { ListBills } from './screens/ListBills';
import { ListPostings } from './screens/ListPostings';
import { DetailBill } from './screens/DetailBill';
import { Dashboard } from './screens/Dashboard';
import { ListHistory } from './screens/ListHistory';

// Hooks
export { useModalPosting } from './hooks/useModalPosting';
export { useModalMovimentDestroy } from './hooks/useModalMovimentDestroy';
export { useModalMovimentDetail } from './hooks/useModalMovimentDetail';

// Components
export { Table };
export { Header } from './components/Header';
export * as Box from 'features/Financial/screens/DetailBill/components/Box';
export * as Panel from 'features/Financial/screens/Dashboard/components/Panel';

// Features
export { Reports } from './features/Reports';

export const Fields = {
  Icon: FieldIcon,
  Account: FieldAccount,
  Category: FieldCategory,
  RecurrencyType: FieldRecurrencyType,
  Situations: FieldSituations,
  CategoryType: FieldCategoryType,
};

export const Financial = {
  Aside,
  Layout,
  Content,
  Nodata,
  DateSelector,
  AddPosting,
};

export const FinancialScreen = {
  ListCategories,
  ListAccounts,
  ListCards,
  ListBills,
  ListPostings,
  DetailBill,
  Dashboard,
  ListHistory,
};
