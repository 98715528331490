import Table from 'components/Table';
import * as S from './styles';
import { Nodata } from './Nodata';
import { useModalMovimentDetail } from '../../../../hooks/useModalMovimentDetail';
import { MdOutlineDelete, MdOutlineMode } from 'react-icons/all';
import { useModalPosting } from '../../../../hooks/useModalPosting';
import { useModalMovimentDestroy } from '../../../../hooks/useModalMovimentDestroy';
import { useDispatch } from 'react-redux';

export function TableMoviments({ moviments, onChange }) {
  const dispatch = useDispatch();
  const { handleOpenModalMovimentDetail } = useModalMovimentDetail();
  const { handleOpenModalPosting } = useModalPosting();
  const { handleOpenModalMovimentDestroy } = useModalMovimentDestroy();

  const handleClickCell = (e) => {
    e.stopPropagation();
  };

  if (!moviments || moviments.length <= 0) {
    return <Nodata />;
  }

  return (
    <S.WrapperTable>
      <Table
        data={moviments}
        renderTitle={() => (
          <tr>
            <th width={124}>Data</th>
            <th width={120}>Ocorrência</th>
            <th>Descrição</th>
            <th width={80} className="Table__cell--money">
              Valor
            </th>
            <th width={80} />
          </tr>
        )}
        renderItem={(moviment) => {
          return (
            <tr
              key={moviment.id}
              onClick={() => {
                handleOpenModalMovimentDetail({
                  movimentId: moviment.id,
                  postingId: moviment.posting_id,
                });
              }}
            >
              <td>{moviment.billing_date}</td>
              <td>{moviment.ocurrency}</td>
              <td>
                <S.Ball
                  color={moviment?.category.color}
                  data-tip={moviment?.category.title}
                />
                {moviment?.description}
              </td>
              <td className="Table__cell--money h-text-nowrap">
                {moviment.value}
              </td>
              <td onClick={handleClickCell}>
                {moviment.can_edit ? (
                  <S.Actions>
                    <S.Action
                      onClick={() => {
                        handleOpenModalPosting({
                          movimentId: moviment.id,
                          postingId: moviment.posting_id,
                          onSuccess: () => {
                            if (onChange) onChange();
                          },
                        });
                      }}
                    >
                      <MdOutlineMode />
                    </S.Action>
                    <S.Action
                      onClick={() => {
                        handleOpenModalMovimentDestroy({
                          movimentId: moviment.id,
                          postingId: moviment.posting_id,
                          onSuccess: () => {
                            if (onChange) onChange();
                          },
                        });
                      }}
                    >
                      <MdOutlineDelete className="h-color--danger" />
                    </S.Action>
                  </S.Actions>
                ) : null}
              </td>
            </tr>
          );
        }}
      />
    </S.WrapperTable>
  );
}
