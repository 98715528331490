import { usePlan } from 'hooks/usePlan';
import {
  Discount,
  Info,
  Infos,
  Main,
  NewPrice,
  OldPrice,
  Prices,
  PricesLabels,
  Title,
  Wrapper,
} from './styles';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { AllInfos } from '../AllInfos';

const PlanItem = ({ label, value, input }) => {
  const { values, prices, getOruloPrice, getDwvPrice } = useModalPayment();

  const checked = value === input.value;

  const [plan] = usePlan({
    months: value,
    users: values.additional_users,
    mailboxes: values.mailboxes_number,
    prices,
    orulo: getOruloPrice(),
    dwv: getDwvPrice(),
  });

  const hasDiscount = plan.percentage > 0;

  function handleSelect() {
    input.onChange(value);
  }

  return (
    <Wrapper active={checked} onClick={handleSelect}>
      <Main>
        <Infos>
          <Title>{label}</Title>
          <AllInfos plan={plan} />
        </Infos>

        {hasDiscount && (
          <PricesLabels>
            <Info>De</Info>
            <Discount>{plan.totalPercentage}% de desconto</Discount>
            <NewPrice>Por</NewPrice>
          </PricesLabels>
        )}
      </Main>

      <Prices>
        {hasDiscount && (
          <>
            <OldPrice>R$ {plan.totalGrossValue}</OldPrice>
            <Info>- R$ {plan.totalDiscount}</Info>
          </>
        )}
        <NewPrice>R$ {plan.totalNetValue}</NewPrice>
      </Prices>
    </Wrapper>
  );
};

export default PlanItem;
