import CreditCardFlag from 'components/CreditCardFlag';
import { Number, Wrapper } from './styles';
import { rgba } from 'polished';
import * as creditCardsService from 'services/financial/creditCards';

const CARDS_COLORS = {
  elo: '#2091C3',
  diners: rgba('#006095', 0.7),
  mastercard: '#4C4B62',
  visa: rgba('#4C4B62', 0.5),
};

const CardItem = ({ creditCard, input, value, onChange }) => {
  const checked = value === input.value;

  function handleSelectCard() {
    if (onChange) onChange(creditCard);
    creditCardsService.defineAsPrimary(value);
    input.onChange(value);
  }

  return (
    <Wrapper
      active={checked}
      color={CARDS_COLORS[creditCard.brand]}
      onClick={handleSelectCard}
    >
      <CreditCardFlag className="icon-card" name={creditCard.brand} />
      <Number>** {creditCard.number}</Number>
    </Wrapper>
  );
};

export default CardItem;
