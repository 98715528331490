import api from 'services/index';
import { responseMessage } from 'lib/service-helpers';

/**
 * Cria uma categoria
 */
export const storePostings = ({
  type = 1,
  description,
  value,
  date,
  account_id,
  category_id,
  repetition_type,
  recurrency_type_id,
  installments,
}) => {
  const message = type === 1 ? 'Receita adicionada' : 'Despesa adicionada';

  return api
    .create('financial/postings', {
      description,
      value,
      date,
      account_id,
      category_id,
      repetition_type,
      recurrency_type_id,
      installments,
    })
    .then(responseMessage(message));
};

export const movimentsList = ({
  start_at,
  end_at,
  situation,
  description,
  account_id,
  category_id,
  category_type,
}) => {
  if (!start_at || !end_at) return;

  const params = {
    start_at,
    end_at,
    situation,
    description,
    account_id,
    category_id,
    category_type,
  };

  if (account_id === 'ALL') {
    delete params.account_id;
  }

  return api.getList('financial/moviments-list', params);
};
