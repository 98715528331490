import { ModalClose } from 'components/Modal';
import { openModalPaymentSuccess } from 'modules/modal';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { useForm } from '../../contexts/form';
import { useModalPayment } from '../../contexts/payment';
import Details from '../../pages/Details';
import Payment from '../../pages/Payment';
import { PlanContract } from '../../pages/PlanContract';
import PaymentError from '../../pages/PaymentError';
import Plan from '../../pages/Plan';
import Loading from 'components/Loading';

const Content = ({
  initialize,
  hasError,
  setHasError,
  initial,
  isLoading,
  paymentMethod,
  isSuccess,
  handleSubmit,
  ...props
}) => {
  const {
    values: { receiving_method },
  } = useModalPayment();

  const { page } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    initialize(initial);
  }, []);

  useEffect(() => {
    if (!isSuccess) return;
    dispatch(openModalPaymentSuccess({ receivingMethod: receiving_method }));
  }, [isSuccess]);

  function renderContent() {
    if (!!hasError) {
      return <PaymentError hasError={hasError} setHasError={setHasError} />;
    }

    switch (page) {
      case 'details':
        return <Details {...props} />;
      case 'plan':
        return <Plan {...props} />;
      case 'planContract':
        return <PlanContract {...props} />;
      case 'payment':
        return <Payment {...props} />;
      default:
        return <Details {...props} />;
    }
  }

  return (
    <form onSubmit={handleSubmit} className="Modal__wrapper">
      <ModalClose handleClose={props.onClose} />
      {isLoading ? <Loading isVisible isFullComponent /> : null}
      {renderContent()}
    </form>
  );
};

export default compose(
  reduxForm({
    form: 'PaymentForm',
    forceUnregisterOnUnmount: true,
  })
)(Content);
