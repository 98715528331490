import { useRequest } from 'hooks/useRequest';
import * as creditCardService from 'services/financial/creditCards';

export const useCreditCard = (params = null) => {
  const { data, meta, fetchData, isFetching } = useRequest({
    request: creditCardService.getAll,
    params,
  });

  return { data, meta, fetchData, isFetching };
};
