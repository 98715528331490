import React from 'react';
import { SelectMultiple } from 'components/Form';
import { Field } from 'redux-form';
import { parseSelect } from 'lib/formHelpers';

export const CATEGORY_TYPE_OPTIONS = [
  { label: 'Receitas', value: 'credito' },
  { label: 'Despesas', value: 'debito' },
];

function FieldCategoryType({
  creatable = true,
  name = 'category_type',
  options,
  excludeId,
  additionalOption,
  onCreate,
  onClickNew,
  placeholder = 'Todos',
  ...props
}) {
  return (
    <Field
      multi={false}
      name={name}
      labelKey="label"
      valueKey="value"
      component={SelectMultiple}
      placeholder={placeholder}
      options={CATEGORY_TYPE_OPTIONS}
      parse={parseSelect('value')}
      {...props}
    />
  );
}

export { FieldCategoryType };
