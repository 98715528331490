// import styles from './styles'

import { Field, reduxForm } from 'redux-form';
import { Input, Textarea } from 'components/Form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import * as S from './styles';
import useFormValue from 'hooks/useFormValue';

function FormAjustAccount({ data, config, handleSubmit, handleClose }) {
  const value = useFormValue(config.fieldName);

  return (
    <form onSubmit={handleSubmit}>
      <S.Row>
        <S.Icon src={data.icon.file_url} />
        <S.Text>{data.title}</S.Text>
      </S.Row>

      <Field
        valueFormat
        prefix="R$ "
        inputProps={{
          decimalScale: 2,
        }}
        label={config.fieldLabel}
        name={config.fieldName}
        component={Input}
      />

      <Field
        type="simple"
        label="Observação"
        name="notes"
        component={Textarea}
      />

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button disabled={!value} type="submit" color="success">
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({ form: 'FormAjustAccount' })(FormAjustAccount);
