import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';

const PlanItem = ({ value, input, children }) => {
  const { values, prices, getOruloPrice, getDwvPrice } = useModalPayment();

  const checked = value === input.value;

  function handleSelect() {
    input.onChange(value);
  }

  return children({
    checked,
    handleSelect,
    input,
    values,
    prices,
    getOruloPrice,
    getDwvPrice,
  });
};

export default PlanItem;
