import Button from 'components/Button';
import Loading from 'components/Loading';
import { ModalFooter } from 'components/Modal';
import { useForm } from 'containers/ModalRoot/ModalPayment/contexts/form';
import { SectionHeader } from 'containers/ModalRoot/ModalPayment/styles';
import useFormValue from 'hooks/useFormValue';
import { usePix } from 'hooks/usePix';
import useReduxForm from 'hooks/useReduxForm';
import { useEffect, useMemo, useState } from 'react';
import {
  FailedVerification,
  Instructions,
  PixWrapper,
  QrCode,
  Value,
} from './styles';
import Clipboard from 'react-clipboard.js';
import Alert from 'react-s-alert';
import * as creditsService from 'services/financial/credits';
import { openModalPaymentSuccess } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { formatter } from 'hooks/usePlan';

const Pix = () => {
  const { plan, plansPreview, values } = useModalPayment();
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);

  const { setPage } = useForm();

  const dispatch = useDispatch();

  const months = useFormValue('months');
  const users = useFormValue('additional_users');
  const mailboxes_number = useFormValue('mailboxes_number');
  const redirect_mails_number = useFormValue('redirect_mails_number');
  const is_dwv = useFormValue('is_dwv');
  const is_orulo = useFormValue('is_orulo');
  const type_plan = useFormValue('type_plan');

  const currentPlanPreview = useMemo(() => {
    try {
      return plansPreview[values.type_plan];
    } catch {
      return null;
    }
  }, [values, plansPreview]);

  const netValue = useMemo(() => {
    if (currentPlanPreview) {
      return currentPlanPreview.final_price;
    }

    return plan.netValue;
  }, [plan, currentPlanPreview]);

  // receiving_method,
  //   credit_id,
  //   plan: months,
  //   users_number: additional_users || 0,
  //   installments,
  //   recurring_charge,
  //   mailboxes_number,
  //   redirect_mails_number,
  //   should_send_mail,
  //   should_send_whatsapp,
  //   with_dwv: is_dwv,
  //   with_orulo: is_orulo,

  const { initialize, getValues } = useReduxForm();

  useEffect(() => {
    const values = getValues();

    const init = function () {
      initialize({
        ...values,
        installments: null,
      });
    };

    init();
  }, []);

  const { code, imageUrl, contract, creditId } = usePix({
    type_plan,
    users,
    months,
    mailboxes_number,
    redirect_mails_number,
    is_dwv,
    is_orulo,
  });

  useEffect(() => {
    contract();
  }, []);

  async function handleVerifyPayment() {
    setIsVerifying(true);

    const verification = await creditsService.getOne(creditId);

    if (verification.data.received_at) {
      dispatch(openModalPaymentSuccess());
    } else {
      setVerificationFailed(true);
    }

    setIsVerifying(false);
  }

  if (!code) return <Loading isVisible isBlock />;

  return (
    <>
      <SectionHeader>Dados do Pix</SectionHeader>

      <PixWrapper>
        <QrCode>
          <img src={imageUrl} alt="QR Code Pix" />
          <Value>1 x R$ {formatter.format(netValue)}</Value>
        </QrCode>

        {verificationFailed ? (
          <FailedVerification>
            <h2>Pagamento não encontrado</h2>
            <p>
              Após confirmação do seu banco, aguarde 30 segundos e clique em
              ”Verificar pagamento”.
            </p>
          </FailedVerification>
        ) : (
          <Instructions>
            <h2>Pagamento via QR Code</h2>
            <p>
              Abra o aplicativo do seu banco, vá a seção Pix e pagar com QR
              CODE.
            </p>

            <Clipboard
              data-clipboard-text={code}
              onClick={() => {
                Alert.success('Código copiado');
              }}
            >
              <Button tagName="span" color="tertiary" size="small">
                Copiar QR Code
              </Button>
            </Clipboard>
          </Instructions>
        )}
      </PixWrapper>

      <ModalFooter>
        <Button
          color="white"
          colorText="primary"
          onClick={() => setPage('plan')}
        >
          Voltar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button
          type="button"
          color="success"
          disabled={isVerifying}
          onClick={handleVerifyPayment}
        >
          {isVerifying ? 'Verificando...' : 'Verificar pagamento'}
        </Button>
      </ModalFooter>
    </>
  );
};

export default Pix;
