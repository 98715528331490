import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { addCurrency } from 'lib/money-helpers';
import * as S from './styles';

export function DonutChart({ data, label }) {
  // Calcula o total
  const total = data.reduce((sum, item) => sum + item.value, 0);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <ResponsiveContainer height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={100}
            dataKey="percent"
            label={({ percent }) => `${percent}%`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="16"
            fontWeight="bold"
          >
            {label}
          </text>
          <Tooltip formatter={(value) => `${value}%`} />
        </PieChart>
      </ResponsiveContainer>
      {/* Adiciona o total abaixo do gráfico */}
      <S.Total>
        <strong>Total: {addCurrency(total)}</strong>
      </S.Total>
    </div>
  );
}
