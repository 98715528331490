import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

const slideInFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50%) translateX(20px); /* Começa fora da tela à direita */
  }
  to {
    opacity: 1;
    transform: translateY(-50%) translateX(0); /* Posição final */
    visibility: visible;
  }
`;

export const RemoveAll = styled.button`
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  white-space: nowrap;
  padding: 5px 15px 5px 5px;
  opacity: 0;
  visibility: hidden;

  button {
    background: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 95px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;

  &:hover ${RemoveAll} {
    animation: ${slideInFromRight} 0.3s ease forwards;
    opacity: 1;
  }
`;

export const Ball = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  background: #2561c2;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.15);
  display: grid;
  place-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;

  :hover {
    background: ${darken(0.05, '#2561c2')};
  }
`;

export const Number = styled.div`
  position: absolute;
  font-weight: 500;
  line-height: 19px;
  bottom: 70%;
  right: 68%;
  padding: 1px 3px;
  font-size: 16px;
  color: #fff;
  background: #f84343;
  border-radius: 4px;
`;
