import { openModal } from 'modules/modal';
import { useDispatch } from 'react-redux';

export const openModalReception =
  ({ onSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalReception',
        size: 945,
        position: 'center',
        props: {
          onSuccess,
        },
      })
    );
  };

export const useModalReception = () => {
  const dispatch = useDispatch();

  const handleOpenModalReception = ({ onSuccess }) => {
    dispatch(
      openModalReception({
        onSuccess,
      })
    );
  };

  return {
    handleOpenModalReception,
  };
};
