import api from 'services';

const RECEIVING_METHODS = {
  BANK_SLIP: 1,
  CREDIT_CARD: 2,
  BONUS: 3,
  PIX: 4,
};

/**
 * Contratação de um novo plano
 * @param type_plan
 * @param consultant_should_not_sent
 * @param consultant_due_date
 * @param consultant_installments
 * @param credit_id
 * @param consultant_id
 * @param users
 * @param additional_users
 * @param months
 * @param installments
 * @param receiving_method
 * @param recurring_charge
 * @param should_send_mail
 * @param should_send_whatsapp
 * @param with_dwv
 * @param with_orulo
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const contractPlan = ({
  type_plan,
  consultant_should_not_sent,
  consultant_due_date,
  consultant_installments,
  credit_id,
  consultant_id,
  additional_users,
  installments = 1,
  months,
  receiving_method,
  recurring_charge,
  should_send_mail,
  should_send_whatsapp,
  mailboxes_number,
  redirect_mails_number,
  is_orulo = false,
  is_dwv = false,
  with_financial = false,
}) => {
  if (credit_id) {
    return api.create(`sales/duplicate/${credit_id}`, { receiving_method });
  }

  return api.create('sales/transactions', {
    consultant_should_not_sent,
    consultant_due_date,
    consultant_installments,
    consultant_id,
    receiving_method,
    credit_id,
    plan: months,
    users_number: additional_users || 0,
    installments,
    recurring_charge,
    mailboxes_number,
    redirect_mails_number,
    should_send_mail,
    should_send_whatsapp,
    with_dwv: is_dwv,
    with_orulo: is_orulo,
    with_financial,
    type_plan,
  });
};

/**
 * Contrata um plano com cartão de crédito
 * @param values
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const contractWithCreditCard = (values) =>
  contractPlan({ receiving_method: RECEIVING_METHODS.CREDIT_CARD, ...values });

/**
 * Contrata um plano com boleto
 * @param values
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const contractWithBankSlip = (values) =>
  contractPlan({ receiving_method: RECEIVING_METHODS.BANK_SLIP, ...values });

/**
 * Contrata um plano com PIX
 * @param values
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const contractWithPix = (values) =>
  contractPlan({
    receiving_method: RECEIVING_METHODS.PIX,
    ...values,
  });
