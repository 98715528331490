import React, { useContext } from 'react';
import Menu from '../../components/Menu';
import MenuItem from '../../components/MenuItem';
import {
  MdEvent,
  MdFormatPaint,
  MdHelp,
  MdPerson,
  MdPieChart,
  MdWork,
} from 'react-icons/md';

import { AiFillBank } from 'react-icons/ai';
import { COLORS } from '../../constants';
import { MenusContext } from 'components/Menus/index';
import { useCan } from 'hooks/useCan';
import { useCurrentUser } from 'hooks/useCurrentUser';

const MenuDefault = () => {
  const { setMenu } = useContext(MenusContext);
  const currentUser = useCurrentUser();
  const canShowFinancial = useCan({
    run: 'VIEW_FINANCIAL',
  });
  const canUseFinancial =
    canShowFinancial && currentUser?.real_estate?.can_use_financial;

  return (
    <Menu title="Menus">
      <MenuItem
        title="Imóveis"
        text="Gerencie seus imóveis"
        onClick={setMenu('MenuProperties')}
      />
      <MenuItem
        icon={MdPerson}
        color={COLORS.blue}
        title="Clientes"
        text="Gerencie seus clientes"
        onClick={setMenu('MenuPeople')}
      />
      <MenuItem
        to="/crm/deals"
        icon={MdWork}
        color={COLORS.green}
        title="CRM"
        text="Seu funil de vendas"
      />
      <MenuItem
        to="/schedule"
        icon={MdEvent}
        color={COLORS.orange}
        title="Agenda"
        text="Organize seu negócio"
      />
      {canUseFinancial ? (
        <MenuItem
          to="/financial/dashboard"
          icon={AiFillBank}
          color={COLORS.purple}
          title="Financeiro"
          text="Financeiro do sistema"
        />
      ) : null}
      <MenuItem
        to="/reports"
        icon={MdPieChart}
        color={COLORS.lightPurple}
        title="Relatórios"
        text="Acompanhe seu negócio"
      />
      <MenuItem
        icon={MdFormatPaint}
        color={COLORS.red}
        title="Site"
        text="Personalização"
        onClick={setMenu('MenuSite')}
      />
      <MenuItem
        icon={MdHelp}
        color={COLORS.yellow}
        title="Ajuda"
        text="Estamos a sua disposição"
        onClick={setMenu('MenuHelp')}
      />
    </Menu>
  );
};

export default MenuDefault;
