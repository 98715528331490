import { useFeaturedLists } from 'hooks/api/featuredLists';
import { useEffect, useMemo } from 'react';
import { Select2 } from 'components/Form';
import { Field } from 'redux-form';

export default function FieldFeaturedLists({ options, ...props }) {
  const { dataFeaturedLists, fetchFeaturedLists } = useFeaturedLists({
    with_all: false,
  });

  const optionsField = useMemo(() => {
    if (options) return options;
    return dataFeaturedLists;
  }, [options, dataFeaturedLists]);

  useEffect(() => {
    if (!options) {
      fetchFeaturedLists();
    }
  }, [options]);

  return (
    <Field
      filter={false}
      name="featured_lists_id"
      options={optionsField}
      component={Select2}
      selectedItemsLabel="galerias"
      placeholder="Selecione a galeria"
      {...props}
    />
  );
}
