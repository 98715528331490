import { useMovimentsMonthly } from 'features/Financial/hooks/useDashboard';

import * as S from './styles';
import { Box } from 'features/Financial';
import { useDashboardContext } from '../../hooks/useDashboardContext';
import { useEffect, useMemo } from 'react';
import { addCurrency, removeCurrency } from 'lib/money-helpers';
import classnames from 'classnames';

export function MovimentsMonthly() {
  const { refreshKey } = useDashboardContext();

  const { movimentsMonthly, fetchMoviments, movimentsMonthlyIsFetching } =
    useMovimentsMonthly();

  const balance = useMemo(() => {
    const credits = removeCurrency(movimentsMonthly.credits);
    const debits = removeCurrency(movimentsMonthly.debits);
    return credits + debits;
  }, [movimentsMonthly]);

  useEffect(() => {
    fetchMoviments();
  }, [refreshKey]);

  return (
    <S.Wrapper>
      <Box.Wrapper>
        <Box.Content>
          <Box.Title>Receitas deste mês</Box.Title>
          <Box.BigText>{movimentsMonthly.credits}</Box.BigText>
        </Box.Content>
      </Box.Wrapper>
      <Box.Wrapper>
        <Box.Content>
          <Box.Title>Despesas deste mês</Box.Title>
          <Box.BigText>{movimentsMonthly.debits}</Box.BigText>
        </Box.Content>
      </Box.Wrapper>
      <Box.Wrapper>
        <Box.Content>
          <Box.Title>Resultado deste mês</Box.Title>
          <Box.BigText
            className={classnames({
              'h-color--danger': balance < 0,
              'h-color--success': balance > 0,
            })}
          >
            {addCurrency(balance)}
          </Box.BigText>
        </Box.Content>
      </Box.Wrapper>
      <Box.Wrapper color="#FD9726">
        <Box.Content>
          <Box.Title>Receitas vencidas</Box.Title>
          <Box.BigText>{movimentsMonthly.overdueCredits}</Box.BigText>
        </Box.Content>
      </Box.Wrapper>
      <Box.Wrapper color="#F84343">
        <Box.Content>
          <Box.Title>Despesas vencidas</Box.Title>
          <Box.BigText>{movimentsMonthly.overdueDebits}</Box.BigText>
        </Box.Content>
      </Box.Wrapper>
    </S.Wrapper>
  );
}
