import React, { useEffect, useMemo } from 'react';
import { Select } from 'components/Form';
import { Field } from 'redux-form';
import useReduxForm from 'hooks/useReduxForm';
import { PAYMENT_METHODS } from 'constants/constants';
import { formatter } from 'hooks/usePlan';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';

const required = (value) =>
  value || typeof value === 'number' ? undefined : 'Campo obrigatório';

const FieldInstallments = ({ typeInstallment, ...props }) => {
  const { months, values, plan, plansPreview, receiving_method } =
    useModalPayment();
  const { dispatch, change } = useReduxForm();

  const currentPlanPreview = useMemo(() => {
    try {
      return plansPreview[values.type_plan];
    } catch {
      return null;
    }
  }, [values, plansPreview]);

  const netValue = useMemo(() => {
    if (currentPlanPreview) {
      return currentPlanPreview.final_price;
    }

    return plan.netValue;
  }, [plan, currentPlanPreview]);

  const installments = useMemo(() => {
    if (plansPreview) {
      return typeInstallment === 'card'
        ? currentPlanPreview?.cc_max_installments
        : currentPlanPreview?.bs_max_installments;
    }

    if (receiving_method === PAYMENT_METHODS.BOLETO)
      return plan.installmentsSlip;

    return plan.installmentsCreditCard;
  }, [plan, receiving_method, currentPlanPreview, typeInstallment]);

  const options = useMemo(() => {
    let opt = [];

    for (let i = installments; i > 0; i--) {
      const total = formatter.format(netValue / i);

      opt = [...opt, { label: `${i}x de R$${total}`, value: i }];
    }

    return opt;
  }, [netValue, installments]);

  useEffect(() => {
    dispatch(change('installments', installments));
  }, [months, installments]);

  return (
    <Field
      label="Total de parcelas"
      name="installments"
      component={Select}
      options={options}
      validate={[required]}
      {...props}
    />
  );
};

export default FieldInstallments;
