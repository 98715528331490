import { Field } from 'redux-form';
import AddCard from '../AddCard';
import CardItem from '../CardItem';
import { Wrapper } from './styles';

const CardsSelect = ({ creditCards, onChange, onAddCard }) => {
  return (
    <Wrapper>
      {creditCards.map((creditCard) => (
        <Field
          name="card"
          component={CardItem}
          creditCard={creditCard}
          onChange={onChange}
          props={{ value: creditCard.id }}
        />
      ))}
      <AddCard onAddCard={onAddCard} />
    </Wrapper>
  );
};

export default CardsSelect;
